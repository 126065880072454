.parent {
  margin-left: -5px;
  margin-right: -5px;
}

/* Create a two-column layout */
.child {
  float: left;
  width: 50%;
  padding: 5px;
}

/* Clearfix (clear floats) */
.parent::after {
  content: "";
  clear: both;
  display: table;
}

.background-color {
  width: 90%;
  padding-left: 0px;
}

#map {
  height: 80vh;
  width: 100%;
  position: relative
}

#deleteToken452 {
  height: 100vh;
  width: 100%;
}

.scrollable-container {
  /*width: 300px;  Adjust the width as needed */
  max-height: 80px;
  /* Adjust the maximum height as needed */
  overflow-y: auto;
  /* Enable vertical scrollbar */
  word-wrap: break-word;
  /* Enable word wrapping */
  /*border: 1px solid #ccc;  Optional: Add border for clarity */
  /*padding: 10px;  Optional: Add padding for spacing */
}

.border-bottom-line {
  border-bottom: 1px solid lightgray;
  padding: 4px 0 4px 0;
}

#the-count {
  text-align: end;
  margin-top: 2px;
}


.history-tl-container {
  font-family: "Roboto", sans-serif;
  margin: auto;
  display: block;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 400px;
}

/* Handle */
.history-tl-container::-webkit-scrollbar-thumb {
  background: skyblue;
}

.history-tl-container ul.tl {
  /* margin: 20px 0; */
  padding-top: 20px;
}

.history-tl-container ul.tl li {
  list-style: none;
  margin: auto;
  /*background: rgba(255,255,0,0.1);*/
  border-left: 1px dashed #86D6FF;
  padding: 0 10px 40px 20px;
  position: relative;
}

.history-tl-container ul.tl li:last-child {
  border-left: 0;
}

.history-tl-container ul.tl li::before {
  position: absolute;
  left: -13px;
  top: -5px;
  content: " ";
  border: 8px solid rgba(255, 255, 255, 0.74);
  border-radius: 500%;
  background: #258CC7;
  height: 25px;
  width: 25px;
  transition: all 500ms ease-in-out;

}

.history-tl-container ul.tl li:hover::before {
  border-color: #258CC7;
  transition: all 1000ms ease-in-out;
}

ul.tl li .item-title {}

ul.tl li .item-detail {
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
}

ul.tl li .timestamp {
  color: #8D8D8D;
  font-size: 12px;
}

.max-height-400 {
  max-height: 400px;
  overflow: auto;
  scrollbar-width: thin;
  width: 70%;
}

.timeline {
  width: 30%;
}

.view-details-images-responsive{
  height: 220px;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .view-detail-main-div {
    display: flex;
    flex-direction: column;
  }

  .timeline {
    width: 100%;
  }

  .max-height-400 {
    width: 100%;
  }

  .view-details-images-responsive {
    height: 300px;
    width: 300px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}